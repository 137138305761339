import React, { useEffect, useState } from 'react';
import './App.css';
import Axios from 'axios';

function App() {
  const [value, setValue] = useState('');
  const axios = Axios.create({
    // baseURL: 'http://127.0.0.1:8787/api',
    baseURL: 'https://serve.mobuiin.workers.dev/api',
    timeout: 1000,
    headers: { 'Content-Type': 'application/json, text/javascript, */*' }
  });

  const [string, setString] = useState();

  useEffect(() => {
    axios.get('/kv-get-data').then(resp => {
      setString(resp.data);
    });
  }, []);

  return (
    <div className="App">
      <div className="mt-20">
        <p>获取到的数据: {string}</p>
        <input
          value={value}
          onChange={e => {
            setValue(e.target.value);
          }}
        />
        <button
          onClick={() => {
            console.log('发送请求', value);
            axios
              .get('/kv-set-data', {
                params: {
                  value: value
                }
              })
              .then(resp => console.log(resp));
          }}
        >
          设置
        </button>
      </div>
    </div>
  );
}

export default App;
